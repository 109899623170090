jQuery( function( $ ) {

	$( '.filter-dropdown' ).on( 'change', function( e ) {

		e.preventDefault();

		$( '#filters-form' ).submit();

		$( '.filter-dropdown' ).attr( 'disabled', 'disabled' );

	} );

} );
