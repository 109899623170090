/*
 * Menus
 */

// On page load
jQuery( function( $ ) {

	// When the mobile menu toggle is clicked
	$( '#mobile-menu-toggle' ).on( 'click', function() {

		// Toggle the mobile menu
		$( '#mobile-menu-nav' ).toggle();

		// Toggle the overlay;
		$( '#menu-overlay' ).toggle();

		// Switch the menu toogle icon
		$( '.mobile-menu-burger, .mobile-menu-close').toggle();

	} );

	// When a mobile menu link with children is clicked
	$( '#mobile-menu-list .menu-item.has-children .menu-link' ).on( 'click', function( e ) {

		// Stop going to the href
		e.preventDefault();

		// Toggle the submenu
		$( this ).siblings( '.submenu-wrapper' ).toggle();

	} );

	// When a desktop menu link with children is clicked
	$( '#desktop-menu-list .menu-item.has-children .menu-link' ).on( 'click', function( e ) {

		// Stop going to the href
		e.preventDefault();

		// Store the clicked element as a variable
		var link_clicked = $( this );

		// If the related submenu is expanded
		if ( link_clicked.hasClass( 'is-active' ) ) {

			// Remove the is-active class and hide the submenu
			link_clicked.removeClass( 'is-active' ).siblings( '.submenu-wrapper' ).hide();

			// Hide the overlay;
			$( '#menu-overlay' ).hide();

			// Only blur on mouse click so keyboard users don't lose focus
			if ( e.originalEvent.detail ) {
    		link_clicked.blur();
		  }

			// Unbind the event listener for a click elsewhere on the document
			$( document ).unbind( 'click' );

		// Otherwise the related submenu isn't expanded
		} else {

			// Shut search
			$( '#desktop-menu-search-wrapper' ).removeClass( 'is-active' ).hide();

			// Toggle the search icons
			$( '#desktop-menu-search-toggle .icon-search' ).show();
			$( '#desktop-menu-search-toggle .icon-search-close' ).hide();

			// Remove all is-active classes
			$( '#desktop-menu-list .menu-link' ).removeClass( 'is-active' )

			// Collapse all open submenus
			$( '#desktop-menu-list .submenu-wrapper' ).hide();

			// Add the is-active class and show the submenu
			link_clicked.addClass( 'is-active' ).siblings( '.submenu-wrapper' ).show();

			// Show the overlay;
			$( '#menu-overlay' ).show();

			// Bind an event listener for a click to the document
			$( document ).bind( 'click', function( e ) {

				// If the click is anywhere outside the top-level menu
				if ( ! $( e.target ).parents( '#desktop-menu-list' ).length ) {

					// Remove all is-active classes
					$( '#desktop-menu-list .menu-link' ).removeClass( 'is-active' )

					// Collapse all submenus
					$( '#desktop-menu-list .submenu-wrapper' ).hide();

					// Hide the overlay;
					$( '#menu-overlay' ).hide();

				}

			});

		}

	} );

	// When a key is pressed
	$( document ).keydown( function( e ) {

		// If it's the escape key
    if ( e.which === 27 ) {

			// Remove all is-active classes
			$( '#desktop-menu-list .menu-link' ).removeClass( 'is-active' )

			// Collapse all submenus
			$( '#desktop-menu-list .submenu-wrapper' ).hide();

		}

	});

} );
