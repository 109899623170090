jQuery( function( $ ) {

	// When the search toggle is clicked
	$( '#desktop-menu-search-toggle' ).on( 'click', function() {

		// Toggle the search form
		$( '#desktop-menu-search-wrapper' ).toggle().toggleClass( 'is-active' );

		// Toggle the search toggle icon
		$( '#desktop-menu-search-toggle .icon-search, #desktop-menu-search-toggle .icon-search-close' ).toggle();

		// If we've just made it active
		if ( $( '#desktop-menu-search-wrapper' ).hasClass( 'is-active' ) ) {

			// Show the overlay
			$( '#menu-overlay' ).show();

			// Hide any open submenus
			$( '#desktop-menu-list .submenu-wrapper' ).hide();
			$( document ).unbind( 'click' );

			// Focus on search input
			$( '#desktop-menu-search-text' ).focus();

		// If we've just hidden it
		} else {

			// Hide the overlay
			$( '#menu-overlay' ).hide();

			// Blur search text and toggle
			$( '#desktop-menu-search-text' ).blur();
			$( '#desktop-menu-search-toggle' ).blur();

		}

	} );

} );
