/*
 * Social sharing script
 */

// On page load
jQuery(function($) {

	// Social share poups
	$('.social-share-button').on('click', function(e) {

		// If it's not an email link
		if (!$(this).hasClass('social-share-email')) {

			// Prevent default action but allow propagation
			e.preventDefault();

			// Pop the window
			var socialShareWindow = window.open(
				$(this).attr('href'),
				'socialWindowTarget',
				'height=285,width=600'
			);

			// Focus the window
			if (window.focus) {
				socialShareWindow.focus();
			}
		}
	});

});
